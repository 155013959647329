@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}
@keyframes showForm {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

body {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vmin;
  background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(25, 37, 84, 1) 100%);
}

img.logo {
  position: absolute;
  border-radius: 50%;
  transition: transform 1s;
  box-shadow: 0px 0px 2px 2px rgba(220, 220, 220, 0.85);
}

img.top {
  transform: translateY(-140%);
}

div.cont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 12%;
  min-width: 200px;
}

div.fullcont {
  width: 80vw;
  min-height: 100vh;
  background-color: #dce4ee;
  background-image: url("raccoon_bg_v3.png");
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

div.header {
  width: 100%;
  display: flex;
  background-color: #2b3f5a;
  padding: 10px;
}

div.header-logo {
  flex-shrink: 0;
  display: flex;
  width: 15%;
  min-width: 100px;
  justify-content: center;
  align-items: center;
}
div.header-logo img {
  border-radius: 50%;
  box-shadow: 0px 0px 2px 2px rgba(220, 220, 220, 0.85);
  max-width: 92px;
  width: 10vw;
}

div.middle-info {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

div.middle-info ul li {
  transition: box-shadow 0.3s, background-color 0.3s;
  padding: 8px;
  border-radius: 8px;
}

div.middle-info ul li:hover {
  box-shadow: 0 0 12px rgba(76, 106, 147, 0.68);
  background-color: rgba(76, 106, 147, 0.12);
}

div.user-info {
  display: flex;
  min-width: 20%;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  font-family: "Radio Canada", sans-serif;
  font-size: 15px;
  color: rgb(226, 226, 226);
  font-size: 0.9rem;
}

.logout {
  margin: 10px;
  cursor: pointer;
}

.message {
  width: 70%;
  margin: 50px;
  text-align: justify;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  flex-grow: 1;
  overflow-x: scroll;
}

.message::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.message {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

span.hint {
  font-size: 12px;
  color: darkgray;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

div.footer {
  width: 100%;
  display: flex;
  background-color: #2b3f5a;
  padding: 10px;
  justify-content: center;
  align-items: center;
  font-family: "Radio Canada", sans-serif;
  color: rgb(226, 226, 226);
  font-size: 0.8rem;
}

#loginForm {
  /* margin: 20px; */
  animation: showForm 1.5s ease 0s 1 normal forwards;
  width: 100%;
}

#loginForm input {
  margin: 5px;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  padding: 5px;
  font-size: 14px;
  border-width: 1px;
  border-color: #6268bc;
  background-color: #e8edfa;
  color: #000000;
  border-style: solid;
  border-radius: 6px;
  box-shadow: 0px 0px 4px rgba(220, 220, 220, 0.75);
  text-align: center;
}

#loginForm button {
  margin: 5px;
  width: 50%;
  font-family: "Montserrat", sans-serif;
  padding: 3px;
  font-size: 13px;
  border-width: 1px;
  border-color: #6268bc;
  background-color: #e8edfa;
  color: #000000;
  border-style: solid;
  border-radius: 6px;
  box-shadow: 0px 0px 4px rgba(220, 220, 220, 0.25);
}

#loginForm button:hover {
  box-shadow: 0px 0px 4px rgba(220, 220, 220, 0.85);
}

.addItemForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
}
div.addItemFormLabel {
  text-align: left;
  color: rgb(236, 236, 236);
  font-size: 14px;
  width: 90%;
  margin-bottom: -4px;
  margin-top: 10px;
}
.addItemForm input {
  margin: 5px;
  /* width: 90%; */
  padding: 5px;
  font-size: 14px;
  border-width: 1px;
  border-color: #6268bc;
  background-color: #e8edfa;
  color: #000000;
  border-style: solid;
  border-radius: 6px;
  box-shadow: 0px 0px 4px rgba(220, 220, 220, 0.75);
  text-align: center;
}

.addItemForm input:focus {
  outline: none;
  box-shadow: 0px 0px 8px rgba(220, 220, 220, 0.75);
}

div.coordinateDiv {
  display: flex;
  width: 90%;
}

.addItemForm button.submitButton {
  margin: 5px;
  width: 50%;
  font-family: "Montserrat", sans-serif;
  padding: 3px;
  font-size: 13px;
  border-width: 1px;
  border-color: #6268bc;
  background-color: #dde5ff;
  color: #000000;
  border-style: solid;
  border-radius: 6px;
  box-shadow: 0px 0px 4px rgba(220, 220, 220, 0.25);
}

.addItemForm .submitButton {
  margin: 12px;
}

.hidden {
  display: none;
}
.visible {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.table {
  font-family: "Ubuntu", sans-serif;
  display: inline-grid;
}
.tableHeader,
.tableRow {
  min-height: 40px;
  min-width: 750px;
  display: inline-grid;
  grid-template-columns: 2fr 3fr 1.5fr 3fr 1.5fr 3fr;
  border: 1px solid #aeaeae;
  margin: 2px 0px;
  width: 100%;
  font-size: 14px;
}
.tableRow:hover,
.buttonHolder button:hover {
  background-color: #f4f4f438;
}
.tableHeader {
  font-weight: bold;
}
.tableCell {
  display: flex;
  justify-content: left;
  text-align: left;
  align-items: center;
  padding: 12px;
}
.buttonHolder,
#errorMessage {
  margin: 12px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
}

.infoHolder {
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
}

.buttonHolder button {
  padding: 8px;
  background-color: transparent;
  border: 1px solid #aeaeae;
  border-radius: 6px;
  font-family: "Ubuntu", sans-serif;
}
#addForm {
  display: none;
}
div#addForm input {
  border-radius: 5px;
  font-family: "Ubuntu", sans-serif;
  font-size: 14px;
  border: 1px solid #aeaeae;
  width: 70%;
  padding: 4px;
}

div.mapCont {
  flex-grow: 1;
  width: 100%;
}

div.addressPopup {
  font-size: 11px;
  color: #313131;
}

div.infoPopup {
  margin-top: 5px;
  margin-bottom: 5px;
}

button.showForm {
  z-index: 999;
  right: 0px;
  position: absolute;
}

div.mapFormCont {
  flex-grow: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.mapForm {
  width: 35%;
  height: 95%;
  background-color: #2b3f5abe;
  border-radius: 8px;
  font-family: "Ubuntu", sans-serif;
}

div.mapFormTitle {
  color: rgb(236, 236, 236);
  font-size: 16px;
  text-align: center;
  margin: 16px;
}

/* ul {
  display: flex;
  gap: 25px;
  list-style-type: none;
  color: #dbdbdb;
  font-family: "Ubuntu", sans-serif;
} */

li a {
  text-decoration: none;
  color: #dbdbdb;
}

.mapIcon {
  border: 1px solid rgb(177, 177, 177);
  border-radius: 50%;
  background-color: #307daa80;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emptyMapIcon {
  border: 1px solid rgb(68, 68, 68);
  border-radius: 50%;
  background-color: #b9b9b980;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.4;
}

button.restartButton {
  margin: 5px;
  font-family: "Montserrat", sans-serif;
  padding: 3px;
  font-size: 13px;
  border-width: 1px;
  border-color: #6268bc;
  background-color: #e8edfa;
  color: #000000;
  border-style: solid;
  border-radius: 6px;
  box-shadow: 0px 0px 4px rgba(220, 220, 220, 0.25);
}

.Toastify__toast-container {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
}

@media only screen and (max-width: 600px) {
  div.fullcont {
    width: 100vw;
  }
  .message {
    width: 90%;
  }
  div.mapForm {
    width: 90%;
  }
}
